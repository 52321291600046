var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var ksa=function(a){var b=i6;return $CLJS.ZF(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},k6=function(a){var b=j6;return $CLJS.ZF(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},l6=function(a){if($CLJS.de(a))return a;throw $CLJS.zj("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.jk,a],null));},m6=function(a){var b=$CLJS.ne(a);return b?
(b=!$CLJS.Ig(a))?$CLJS.Gm.h($CLJS.pf,$CLJS.Jb)($CLJS.C(a)):b:b},j6=function(a){return $CLJS.m($CLJS.Gm.h($CLJS.pf,$CLJS.Jb)(a))?$CLJS.J_(a):a},n6=function(a,b){var c=m6(b);return $CLJS.m(c)?(b=j6($CLJS.C(b)),$CLJS.ke(a)?(a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c},lsa=function(a){return $CLJS.M.j(new $CLJS.k(null,3,[$CLJS.Pj,$CLJS.Ye,$CLJS.Rk,j6,$CLJS.cJ,j6],null),a,function(b){var c=$CLJS.M,d=c.h;b=$CLJS.Pf([a,b]);b=o6.h?o6.h(b,p6):o6.call(null,b,p6);return d.call(c,b,a)})},msa=function(a){a=
$CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=j6(c);var d=lsa(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.Rk.g(a),$CLJS.cI)&&$CLJS.Gb($CLJS.cJ.g(a))?$CLJS.U.j(a,$CLJS.cJ,$CLJS.DR):a},nsa=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.e_(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.U.j(msa(b),$CLJS.V,c)],null)}),a)},q6=function(a){return $CLJS.q_.j($CLJS.q_.j($CLJS.Sb(function(b,
c){return $CLJS.q_.j(b,c,$CLJS.Oi)},a,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.tJ,$CLJS.ega,$CLJS.BP,osa,$CLJS.rF,$CLJS.en],null)),$CLJS.JG,$CLJS.Em.h(i6,o6)),$CLJS.dP,$CLJS.XZ)},r6=function(a){return $CLJS.Ae(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,null],null):a},t6=function(a){return s6.g(r6(a))},u6=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.g_($CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.h(s6,b)))},v6=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);
b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,t6(b)],null),$CLJS.rg.h(s6,c))},w6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.S(null,2,5,$CLJS.T,[b,t6(a)],null):new $CLJS.S(null,1,5,$CLJS.T,[b],null)},x6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,t6(a)],null)},y6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,s6.g(a)],null)},i6=function(a){return z6(function(b){if($CLJS.oe(b))return ksa(b);
if($CLJS.Gb(m6(b)))return b;try{return s6.g(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.XF($CLJS.Wu);if($CLJS.m($CLJS.dG("metabase.mbql.normalize",d))){var e=$CLJS.VH("Invalid clause:");e instanceof Error?$CLJS.eG("metabase.mbql.normalize",d,$CLJS.UB.l($CLJS.G([b])),e):$CLJS.eG("metabase.mbql.normalize",d,$CLJS.UB.l($CLJS.G([e,b])),null)}throw $CLJS.yj($CLJS.UH("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.SZ(c)])),new $CLJS.k(null,1,[$CLJS.iS,b],null),c);}throw f;}},a)},psa=function(a){return $CLJS.Mg($CLJS.eg(i6,
A6(a)))},qsa=function(a){for(;;)if($CLJS.m(m6(a)))a=new $CLJS.S(null,1,5,$CLJS.T,[a],null);else return $CLJS.Rf($CLJS.Im.h(r6,a))},B6=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.cI);return $CLJS.m(b)?$CLJS.Jm.j(a,$CLJS.cI,s6):a},rsa=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);
q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,B6(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,B6(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},C6=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.hR);return $CLJS.m(b)?$CLJS.Jm.j(a,$CLJS.hR,rsa):a},ssa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.TQ);b=
$CLJS.Gb(a)?D6.g?D6.g(b):D6.call(null,b):b;return $CLJS.m(a)?C6(b):b},E6=function(a){return $CLJS.ke(a)?$CLJS.A(a):null!=a},tsa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.uO);var c=$CLJS.M.h(b,$CLJS.NO),d=$CLJS.M.h(b,$CLJS.NP),e=$CLJS.M.h(b,$CLJS.TQ);try{if($CLJS.m(d)){var f=$CLJS.Qf(b),g=$CLJS.M.h(f,$CLJS.NP);var l=$CLJS.Pu($CLJS.wm.h(f,$CLJS.NP),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uO,$CLJS.NP],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.Jm.j(l,$CLJS.uO,D6):l,q=$CLJS.m(c)?$CLJS.Jm.j(n,$CLJS.NO,
$CLJS.bg($CLJS.Im,i6)):n,u=$CLJS.m(e)?$CLJS.Jm.j(q,$CLJS.TQ,C6):q;return i6(u)}catch(v){if(v instanceof Error)throw n=v,$CLJS.yj($CLJS.UH("Error canonicalizing query: {0}",$CLJS.G([$CLJS.SZ(n)])),new $CLJS.k(null,1,[$CLJS.uO,a],null),n);throw v;}},usa=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.uO),c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.FP);c=$CLJS.M.h(c,$CLJS.wO);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.si($CLJS.Ve($CLJS.qg,$CLJS.A($CLJS.ug($CLJS.Hb,function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{var l=
$CLJS.Td(f,0);if($CLJS.O(l,$CLJS.DQ)){var n=$CLJS.Td(f,1),q=$CLJS.Td(f,2);return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,n,$CLJS.wm.h(q,$CLJS.PQ)],null)],null)],null)}throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.sZ(g,e,f);throw l;}throw u;}}($CLJS.Lg,b))))),$CLJS.p_.j(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uO,
$CLJS.wO],null),$CLJS.Em.h($CLJS.Mg,$CLJS.bg($CLJS.Hm,b)))):a},vsa=function(a){try{return usa(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.yj($CLJS.VH("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.uO,a],null),b);}throw c;}},wsa=function(a,b){var c=$CLJS.oh.h($CLJS.ae(a),function(){return function f(e){return new $CLJS.yf(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var y=0;;)if(y<
n){var B=$CLJS.be(l,y),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=$CLJS.kf.h(b,H);B=F6.h?F6.h(B,I):F6.call(null,B,I);null!=B&&q.add(new $CLJS.S(null,2,5,$CLJS.T,[H,B],null));y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);u=function(){var y=x,B=$CLJS.kf.h(b,v);return F6.h?F6.h(y,B):F6.call(null,y,B)}();if(null!=u)return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null),f($CLJS.zd(g)));g=$CLJS.zd(g)}else return null},
null,null)}(a)}());return $CLJS.A(c)?c:null},xsa=function(a,b){a=$CLJS.Im.h(function(c){var d=$CLJS.kf.h(b,G6);return F6.h?F6.h(c,d):F6.call(null,c,d)},a);return $CLJS.m($CLJS.Xf($CLJS.Hb,a))?a:null},z6=function z6(a,b){return $CLJS.VZ($CLJS.bg(z6,a),$CLJS.Ye,a.g?a.g(b):a.call(null,b))},ysa=new $CLJS.N(null,"value_field","value_field",-980977878),H6=new $CLJS.N(null,"ascending","ascending",-988350486),I6=new $CLJS.N(null,"named","named",-422393479),J6=new $CLJS.N(null,"values_source_config","values_source_config",
-590570309),zsa=new $CLJS.N(null,"field-id","field-id",-353751335),K6=new $CLJS.N(null,"descending","descending",-24766135),L6=new $CLJS.N(null,"binning-strategy","binning-strategy",2063329158),M6=new $CLJS.N(null,"datetime-field","datetime-field",21731696),Asa=new $CLJS.N(null,"use-as-display-name?","use-as-display-name?",686752941),N6=new $CLJS.N(null,"field-literal","field-literal",-1295883554),O6=new $CLJS.N(null,"viz-settings","viz-settings",256055379),Bsa=new $CLJS.N(null,"rows","rows",850049680),
Csa=new $CLJS.N(null,"special-fn","special-fn",1290649344),G6=new $CLJS.N("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),p6=new $CLJS.N(null,"ignore-path","ignore-path",944069061),Dsa=new $CLJS.N(null,"label_field","label_field",-1573182765),Esa=new $CLJS.N(null,"fk-\x3e","fk-\x3e",-499026738),Fsa=new $CLJS.N(null,"joined-field","joined-field",-2048778268),osa=new $CLJS.N(null,"visibility_type","visibility_type",-508434247);var P6,Gsa=$CLJS.cj.g($CLJS.P),Hsa=$CLJS.cj.g($CLJS.P),Isa=$CLJS.cj.g($CLJS.P),Jsa=$CLJS.cj.g($CLJS.P),Ksa=$CLJS.M.j($CLJS.P,$CLJS.Mk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));P6=new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Em.h(j6,$CLJS.C),$CLJS.Pj,Ksa,Gsa,Hsa,Isa,Jsa);P6.o(null,$CLJS.MP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,b],null);return null!=a?$CLJS.kf.h(b,a):b});
P6.o(null,$CLJS.zG,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,a instanceof $CLJS.N?$CLJS.e_(a):a],null)});P6.o(null,L6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?$CLJS.kf.h(P6.g(new $CLJS.S(null,3,5,$CLJS.T,[L6,b,c],null)),a):new $CLJS.S(null,3,5,$CLJS.T,[L6,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c)],null)});
P6.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=o6.h?o6.h(a,p6):o6.call(null,a,p6);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,function(){var d=$CLJS.m($CLJS.CG.g(c))?$CLJS.Jm.j(c,$CLJS.CG,$CLJS.Oi):c;d=$CLJS.m($CLJS.PQ.g(c))?$CLJS.Jm.j(d,$CLJS.PQ,$CLJS.Oi):d;return $CLJS.m($CLJS.OR.g(c))?$CLJS.Jm.j(d,$CLJS.OR,function(e){return $CLJS.m($CLJS.WK.g(e))?$CLJS.Jm.j(e,$CLJS.WK,$CLJS.Oi):e}):d}()],null)});
P6.o(null,N6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[N6,b instanceof $CLJS.N?$CLJS.e_(b):b,$CLJS.Oi.g(a)],null)});P6.o(null,M6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[M6,o6.h?o6.h(b,p6):o6.call(null,b,p6),$CLJS.wu,j6(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[M6,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c)],null)});
P6.o(null,$CLJS.qK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);return $CLJS.m(a)?$CLJS.kf.h(P6.g(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qK,b,c,d],null)),o6.h?o6.h(a,p6):o6.call(null,a,p6)):new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qK,o6.h?o6.h(b,p6):o6.call(null,b,p6),$CLJS.Ae(c)?c:j6(c),j6(d)],null)});
P6.o(null,$CLJS.rJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rJ,b,j6(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rJ,$CLJS.mB],null)});P6.o(null,$CLJS.iJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iJ,b,j6(a)],null)});
P6.o(null,$CLJS.dK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.dK,o6.h?o6.h(b,p6):o6.call(null,b,p6),c,j6(a)],null)});P6.o(null,$CLJS.LK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.LK,o6.h?o6.h(b,p6):o6.call(null,b,p6),c,j6(a)],null)});
P6.o(null,$CLJS.cK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cK,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cK,o6.h?o6.h(b,p6):o6.call(null,b,p6)],null)});
P6.o(null,$CLJS.oK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.oK,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c),j6(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oK,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c)],null)});
P6.o(null,$CLJS.hJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.hJ,o6.h?o6.h(b,p6):o6.call(null,b,p6),o6.h?o6.h(c,p6):o6.call(null,c,p6),j6(a)],null)});P6.o(null,$CLJS.ll,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ll,b,a],null)});
P6.o(null,$CLJS.Pj,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[j6(a)],null),$CLJS.rg.g(function(c){return o6.h?o6.h(c,p6):o6.call(null,c,p6)}),b)});
var Q6=function Q6(a){if($CLJS.m($CLJS.Gm.h($CLJS.pf,$CLJS.Jb)(a))){var c=j6(a);var d=new $CLJS.ni(null,new $CLJS.k(null,18,[$CLJS.mk,null,$CLJS.fm,null,$CLJS.zK,null,$CLJS.eK,null,$CLJS.xu,null,$CLJS.MJ,null,$CLJS.NK,null,$CLJS.PK,null,$CLJS.MI,null,$CLJS.mJ,null,$CLJS.em,null,$CLJS.QI,null,$CLJS.uK,null,$CLJS.YJ,null,$CLJS.Sl,null,$CLJS.kD,null,$CLJS.CJ,null,$CLJS.sK,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(m6(a))?(a=$CLJS.C(a),Q6.g?Q6.g(a):Q6.call(null,
a)):null},Lsa=new $CLJS.k(null,8,[$CLJS.Rk,j6,$CLJS.TQ,function(a){a=k6(a);return $CLJS.A($CLJS.hR.g(a))?$CLJS.Jm.j(a,$CLJS.hR,nsa):a},$CLJS.uO,new $CLJS.k(null,6,[$CLJS.MP,function R6(a){if($CLJS.m($CLJS.Gm.h($CLJS.pf,$CLJS.Jb)(a)))return j6(a);if($CLJS.m(n6(I6,a))){a=$CLJS.A(a);$CLJS.C(a);var c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[I6,R6.g?R6.g(a):R6.call(null,a)],null),c)}return $CLJS.m($CLJS.m(m6(a))?Q6($CLJS.Zd(a)):null)?$CLJS.Im.h(R6,a):o6.h?o6.h(a,
p6):o6.call(null,a,p6)},$CLJS.OQ,function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.e_(u),o6.h?o6.h(q,p6):o6.call(null,q,p6)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.e_(l),o6.h?o6.h(f,p6):o6.call(null,f,p6)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.YR,function(a){return $CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=$CLJS.m(m6(q))?P6.g(q):$CLJS.lf(P6.g($CLJS.lf(q)));l.add(q);
n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf($CLJS.m(m6(l))?P6.g(l):$CLJS.lf(P6.g($CLJS.lf(l))),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.kR,function(a){a=k6(a);a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.TQ);if($CLJS.m(b))return a=$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.TQ,$CLJS.uO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.TQ],null),a=o6.h?o6.h(a,b):o6.call(null,a,b),$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.uO,$CLJS.TQ],
null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.uO],null);return o6.h?o6.h(a,b):o6.call(null,a,b)},$CLJS.NP,new $CLJS.k(null,1,[G6,q6],null),$CLJS.UO,new $CLJS.k(null,1,[G6,function(a){a=o6.h?o6.h(a,$CLJS.uO):o6.call(null,a,$CLJS.uO);var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.WK),d=$CLJS.M.h(b,$CLJS.wO);a=$CLJS.M.h(b,$CLJS.vP);b=$CLJS.m(c)?$CLJS.Jm.j(b,$CLJS.WK,j6):b;d=$CLJS.m($CLJS.Gm.h($CLJS.pf,$CLJS.Jb)(d))?$CLJS.Jm.j(b,$CLJS.wO,j6):b;return $CLJS.m(a)?$CLJS.Jm.j(d,$CLJS.vP,$CLJS.e_):d}],null)],null),
$CLJS.ZD,new $CLJS.k(null,1,[$CLJS.nP,$CLJS.Ye],null),$CLJS.NO,new $CLJS.k(null,1,[G6,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Rk),d=$CLJS.M.h(b,$CLJS.$K),e=$CLJS.M.h(b,$CLJS.Ck);a=$CLJS.M.h(b,J6);b=$CLJS.m(e)?$CLJS.Jm.j(b,$CLJS.Ck,$CLJS.e_):b;c=$CLJS.m(c)?$CLJS.Jm.j(b,$CLJS.Rk,j6):b;d=$CLJS.m(d)?$CLJS.Jm.j(c,$CLJS.$K,function(f){return o6.h?o6.h(f,p6):o6.call(null,f,p6)}):c;d=$CLJS.m(a)?$CLJS.p_.j(d,new $CLJS.S(null,2,5,$CLJS.T,[J6,Dsa],null),function(f){return o6.h?o6.h(f,p6):o6.call(null,
f,p6)}):d;return $CLJS.m(a)?$CLJS.p_.j(d,new $CLJS.S(null,2,5,$CLJS.T,[J6,ysa],null),function(f){return o6.h?o6.h(f,p6):o6.call(null,f,p6)}):d}],null),$CLJS.LO,function(a){return null==a?null:j6(a)},$CLJS.NP,new $CLJS.k(null,1,[G6,q6],null),O6,j6],null),o6=function o6(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return o6.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
o6.l=function(a,b){b=$CLJS.J(b,0,null);var c=b instanceof $CLJS.N?new $CLJS.S(null,1,5,$CLJS.T,[b],null):$CLJS.Mg(b);b=$CLJS.A(c)?$CLJS.Ju(Lsa,c):null;try{return $CLJS.de(b)?b.g?b.g(a):b.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?$CLJS.oh.h($CLJS.P,function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=j6(y);y=new $CLJS.S(null,
2,5,$CLJS.T,[y,o6.l(x,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),y)]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);u=j6(u);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,o6.l(n,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),u)]))],null),g($CLJS.zd(l)))}return null}},null,null)}(a)}()):$CLJS.m(m6(a))?P6.g(a):$CLJS.ne(a)?$CLJS.Im.h(function(e){return o6.l(e,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),G6)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.yj($CLJS.UH("Error normalizing form: {0}",$CLJS.G([$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.qF,a,$CLJS.Ul,c,Csa,b],null),d);}throw e;}};o6.v=1;o6.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var s6=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Mk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(m6(f))?$CLJS.C(f):null},$CLJS.Pj,e,a,b,c,d)}();s6.o(null,$CLJS.Pj,function(a){return a});
s6.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(n6($CLJS.DQ,b))){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return s6.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,$CLJS.Rf($CLJS.Zm.l($CLJS.G([b,a])))],null))}return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,$CLJS.Rf(a)],null)});
s6.o(null,$CLJS.MP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.je(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,b],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MP,b,a],null)});s6.o(null,zsa,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(m6(a))?s6.g(a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,null],null)});
s6.o(null,N6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,new $CLJS.k(null,1,[$CLJS.CG,a],null)],null)});s6.o(null,Esa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=t6(b);$CLJS.J(c,0,null);b=$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=t6(a);$CLJS.J(c,0,null);a=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,$CLJS.U.j(c,$CLJS.VO,b)],null)});
s6.o(null,Fsa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=t6(a);return $CLJS.R.D($CLJS.t_,a,$CLJS.U,$CLJS.G([$CLJS.lQ,b]))});
s6.o(null,M6,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.J(a,0,null);b=$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null);a=t6(b);b=c;$CLJS.J(a,0,null);$CLJS.J(a,1,null);c=$CLJS.J(a,2,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.CG);return $CLJS.Gb(c)||$CLJS.GS.h(c,b)?$CLJS.R.D($CLJS.t_,a,$CLJS.U,$CLJS.G([$CLJS.PQ,b])):a;case 4:return $CLJS.J(a,0,null),b=$CLJS.J(a,1,null),$CLJS.J(a,2,null),c=$CLJS.J(a,3,null),s6.g(new $CLJS.S(null,3,5,$CLJS.T,[M6,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.p.g(b)].join(""));}});s6.o(null,L6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);var e=t6(b);$CLJS.J(e,0,null);b=$CLJS.J(e,1,null);e=$CLJS.J(e,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,$CLJS.U.j(e,$CLJS.OR,$CLJS.Zm.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.WK,c],null),$CLJS.m(d)?$CLJS.Pf([c,d]):null,a])))],null)});
for(var S6=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.xk,$CLJS.Ok],null)),T6=null,U6=0,V6=0;;)if(V6<U6){var W6=T6.$(null,V6);s6.o(null,W6,function(){return function(a){return u6(a)}}(S6,T6,U6,V6,W6));V6+=1}else{var X6=$CLJS.A(S6);if(X6){var Y6=X6;if($CLJS.re(Y6)){var Z6=$CLJS.$c(Y6),Msa=$CLJS.ad(Y6),Nsa=Z6,Osa=$CLJS.E(Z6);S6=Msa;T6=Nsa;U6=Osa}else{var $6=$CLJS.C(Y6);s6.o(null,$6,function(){return function(a){return u6(a)}}(S6,T6,U6,V6,$6,Y6,X6));S6=$CLJS.D(Y6);T6=null;U6=0}V6=0}else break}
s6.o(null,$CLJS.UI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UI,t6(a),t6(b)],null),c)});s6.o(null,$CLJS.qK,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=t6(a);a=$CLJS.m($CLJS.f_($CLJS.DQ,a))?$CLJS.t_.l(c,$CLJS.wm,$CLJS.G([$CLJS.PQ])):c;return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qK,a],null),b)});
for(var a7=$CLJS.A(new $CLJS.S(null,15,5,$CLJS.T,[$CLJS.uJ,$CLJS.mK,$CLJS.KJ,$CLJS.vJ,$CLJS.$l,$CLJS.CK,$CLJS.am,$CLJS.mm,$CLJS.cm,$CLJS.lm,$CLJS.KI,$CLJS.$I,$CLJS.xI,$CLJS.nJ,$CLJS.JI],null)),b7=null,c7=0,d7=0;;)if(d7<c7){var e7=b7.$(null,d7);s6.o(null,e7,function(){return function(a){return v6(a)}}(a7,b7,c7,d7,e7));d7+=1}else{var f7=$CLJS.A(a7);if(f7){var g7=f7;if($CLJS.re(g7)){var h7=$CLJS.$c(g7),Psa=$CLJS.ad(g7),Qsa=h7,Rsa=$CLJS.E(h7);a7=Psa;b7=Qsa;c7=Rsa}else{var i7=$CLJS.C(g7);s6.o(null,i7,
function(){return function(a){return v6(a)}}(a7,b7,c7,d7,i7,g7,f7));a7=$CLJS.D(g7);b7=null;c7=0}d7=0}else break}s6.o(null,Bsa,function(){return null});s6.o(null,$CLJS.nO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nO,s6.g(b),a],null)});
s6.o(null,I6,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=s6.g;var e=$CLJS.T;b=s6.g(b);d=$CLJS.J(d,0,null);d=$CLJS.Qf(d);a=!1===$CLJS.M.h(d,Asa)?new $CLJS.k(null,1,[$CLJS.V,a],null):new $CLJS.k(null,1,[$CLJS.TI,a],null);return c.call(s6,new $CLJS.S(null,3,5,e,[$CLJS.nO,b,a],null))});
for(var j7=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kD,$CLJS.MJ],null)),k7=null,l7=0,m7=0;;)if(m7<l7){var n7=k7.$(null,m7);s6.o(null,n7,function(){return function(a){return w6(a)}}(j7,k7,l7,m7,n7));m7+=1}else{var o7=$CLJS.A(j7);if(o7){var p7=o7;if($CLJS.re(p7)){var q7=$CLJS.$c(p7),Ssa=$CLJS.ad(p7),Tsa=q7,Usa=$CLJS.E(q7);j7=Ssa;k7=Tsa;l7=Usa}else{var r7=$CLJS.C(p7);s6.o(null,r7,function(){return function(a){return w6(a)}}(j7,k7,l7,m7,r7,p7,o7));j7=$CLJS.D(p7);k7=null;l7=0}m7=0}else break}
for(var s7=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.CJ,$CLJS.QK,$CLJS.MI,$CLJS.zK,$CLJS.sK,$CLJS.mk,$CLJS.Sl,$CLJS.uK,$CLJS.QI],null)),t7=null,u7=0,v7=0;;)if(v7<u7){var w7=t7.$(null,v7);s6.o(null,w7,function(){return function(a){return x6(a)}}(s7,t7,u7,v7,w7));v7+=1}else{var x7=$CLJS.A(s7);if(x7){var y7=x7;if($CLJS.re(y7)){var z7=$CLJS.$c(y7),Vsa=$CLJS.ad(y7),Wsa=z7,Xsa=$CLJS.E(z7);s7=Vsa;t7=Wsa;u7=Xsa}else{var A7=$CLJS.C(y7);s6.o(null,A7,function(){return function(a){return x6(a)}}(s7,t7,u7,v7,
A7,y7,x7));s7=$CLJS.D(y7);t7=null;u7=0}v7=0}else break}s6.o(null,$CLJS.mJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mJ,t6(b),a],null)});
for(var B7=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.YJ,$CLJS.eK],null)),C7=null,D7=0,E7=0;;)if(E7<D7){var F7=C7.$(null,E7);s6.o(null,F7,function(){return function(a){return y6(a)}}(B7,C7,D7,E7,F7));E7+=1}else{var G7=$CLJS.A(B7);if(G7){var H7=G7;if($CLJS.re(H7)){var I7=$CLJS.$c(H7),Ysa=$CLJS.ad(H7),Zsa=I7,$sa=$CLJS.E(I7);B7=Ysa;C7=Zsa;D7=$sa}else{var J7=$CLJS.C(H7);s6.o(null,J7,function(){return function(a){return y6(a)}}(B7,C7,D7,E7,J7,H7,G7));B7=$CLJS.D(H7);C7=null;D7=0}E7=0}else break}
s6.o(null,$CLJS.NK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NK,s6.g(b),s6.g(a)],null)});
s6.o(null,$CLJS.ZI,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?$CLJS.kf.h(s6.g(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZI,b],null)),o6.l(a,$CLJS.G([p6]))):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZI,$CLJS.Mg(function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,
2,5,$CLJS.T,[s6.g(v),s6.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[s6.g(n),s6.g(g)],null),e($CLJS.zd(f)))}return null}},null,null)}(b)}())],null)});
s6.o(null,$CLJS.aI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aI,s6.g(a),$CLJS.F.h(0,b)?1:s6.g(b)],null),$CLJS.rg.h(s6,c))});
var A6=function A6(a){return function f(d,e){try{if($CLJS.m(function(){var x=l6($CLJS.we);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var y=l6($CLJS.pf);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.qe(e)&&2<=$CLJS.E(e))try{var g=$CLJS.Om.j(e,
0,2);if($CLJS.qe(g)&&2===$CLJS.E(g))try{var l=$CLJS.Td(g,0);if($CLJS.m($CLJS.Fm.h($CLJS.pf,$CLJS.$f(new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.fm,null,$CLJS.xu,null,$CLJS.PK,null,$CLJS.em,null,I6,null],null),null)))(l)))try{var n=$CLJS.Td(g,1);if($CLJS.m(Q6(n)))return $CLJS.Mg($CLJS.Ve($CLJS.qg,$CLJS.rg.h(A6,a)));throw $CLJS.Z;}catch(B){if(B instanceof Error){var q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;
throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error)if(q=B,q===$CLJS.Z)try{if($CLJS.qe(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Om.j(e,0,1);if($CLJS.qe(u)&&1===$CLJS.E(u))try{if($CLJS.Td(u,0)instanceof $CLJS.N)return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw B;}else throw d;else throw y;}else throw d;else throw x;}}($CLJS.Lg,a)},K7=function K7(a){return function f(d,e){try{var g=l6($CLJS.we);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(B){if(B instanceof Error)if(l=B,l===$CLJS.Z)try{if($CLJS.qe(e)&&2===$CLJS.E(e))try{var n=$CLJS.Td(e,
1);if($CLJS.O(n,$CLJS.FJ)){var q=$CLJS.Td(e,0);return f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,$CLJS.sJ))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,q],null));throw $CLJS.Z;}catch(I){if(I instanceof Error){var u=I;if(u===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,H6))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,q],null));throw $CLJS.Z;}catch(Q){if(Q instanceof
Error)if(l=Q,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,K6))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,q],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(n=Y,n===$CLJS.Z)try{var v=$CLJS.Td(e,0);if($CLJS.O(v,H6))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,q],null));throw $CLJS.Z;}catch(aa){if(aa instanceof Error)if(n=aa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,K6))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,q],null));
throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(n=ha,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.FJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,t6(q)],null);throw $CLJS.Z;}catch(qa){if(qa instanceof Error)if(n=qa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.sJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,t6(q)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){q=Ea;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Ea;}else throw n;else throw qa;
}else throw n;else throw ha;}else throw n;else throw aa;}else throw n;else throw Y;}else throw l;else throw Q;}else throw u;}else throw I;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.qe(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Om.j(e,0,0);if($CLJS.qe(x)&&0===$CLJS.E(x))try{var y=$CLJS.Om.h(e,0);if($CLJS.F.h(y,a))return $CLJS.Mg($CLJS.an.g($CLJS.rg.h(K7,a)));throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;
}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)return $CLJS.d_(f,d,e);throw u;}throw I;}else throw l;else throw H;}else throw l;else throw B;}}($CLJS.Lg,a)},D6=$CLJS.Em.h(i6,function(a){var b=E6($CLJS.MP.g(a))?$CLJS.Jm.j(a,$CLJS.MP,psa):a;b=E6($CLJS.FP.g(a))?$CLJS.Jm.j(b,$CLJS.FP,qsa):b;b=E6($CLJS.wO.g(a))?$CLJS.Jm.j(b,$CLJS.wO,$CLJS.bg($CLJS.Im,r6)):b;b=E6($CLJS.YR.g(a))?
$CLJS.Jm.j(b,$CLJS.YR,K7):b;return E6($CLJS.kR.g(a))?$CLJS.Jm.j(b,$CLJS.kR,ssa):b}),ata=new $CLJS.k(null,3,[$CLJS.TQ,$CLJS.Ye,$CLJS.uO,new $CLJS.k(null,2,[$CLJS.kR,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.TQ);if($CLJS.m(b))return a=$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.TQ,$CLJS.uO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.TQ],null),a=F6.h?F6.h(a,b):F6.call(null,a,b),$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.uO,$CLJS.TQ],null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.uO],null);return F6.h?F6.h(a,
b):F6.call(null,a,b)},$CLJS.UO,new $CLJS.k(null,1,[G6,function(a){var b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.uO],null);return F6.h?F6.h(a,b):F6.call(null,a,b)}],null)],null),O6,$CLJS.Ye],null),F6=function F6(a){switch(arguments.length){case 1:return F6.g(arguments[0]);case 2:return F6.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};F6.g=function(a){return F6.h(a,$CLJS.Lg)};
F6.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.Ju(ata,b):null;return $CLJS.de(c)?c.g?c.g(a):c.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?wsa(a,b):$CLJS.ne(a)?xsa(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.yj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.qF,a,$CLJS.Ul,b],null),d);throw d;}};F6.v=2;
$CLJS.L7=function(){var a=$CLJS.Em.l(F6,vsa,tsa,$CLJS.G([o6]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.yj($CLJS.UH("Error normalizing query: {0}",$CLJS.G([$CLJS.SZ(c)])),new $CLJS.k(null,1,[$CLJS.uO,b],null),c);}throw d;}}}();