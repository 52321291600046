var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var nX,oX,yma,zma,Ama,qX,rX,sX,tX,vX,Bma,xX;nX=function(a){switch(arguments.length){case 2:return $CLJS.nV(arguments[0],arguments[1]);case 3:return $CLJS.mV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};oX=function(a){return["(?:",$CLJS.p.g($CLJS.R.h($CLJS.p,a)),")?"].join("")};yma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
zma=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.pX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Ama=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);qX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
rX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);sX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);tX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.uX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
vX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Bma=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.wX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);xX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.yX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.AL.o(null,$CLJS.FG,function(){return $CLJS.il});$CLJS.jL(xX,$CLJS.hk);$CLJS.AL.o(null,$CLJS.MG,function(){return $CLJS.ul});$CLJS.jL(xX,$CLJS.hk);$CLJS.jL(Bma,$CLJS.Rt);$CLJS.AL.o(null,$CLJS.uG,function(){return $CLJS.dl});$CLJS.jL(zma,$CLJS.Qt);$CLJS.AL.o(null,$CLJS.sG,function(){return $CLJS.KK});$CLJS.jL(Ama,$CLJS.Il);
var Cma=[":\\d{2}",oX($CLJS.G(["\\.\\d{1,6}"]))].join(""),zX=["\\d{2}:\\d{2}",oX($CLJS.G([Cma]))].join(""),AX=["\\d{4}-\\d{2}-\\d{2}T",zX].join(""),BX=["(?:Z|(?:[+-]",zX,"))"].join(""),Dma=$CLJS.Fi("^\\d{4}-\\d{2}-\\d{2}$"),Ema=$CLJS.Fi(["^",zX,"$"].join("")),Fma=$CLJS.Fi(["^",zX,BX,"$"].join("")),Gma=$CLJS.Fi(["^",AX,"$"].join("")),Hma=$CLJS.Fi(["^",AX,BX,"$"].join(""));$CLJS.jL(sX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"date string literal"],null),Dma],null));
$CLJS.jL(rX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"local time string literal"],null),Ema],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"offset time string literal"],null),Fma],null)],null));
$CLJS.jL(tX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"local date time string literal"],null),Gma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"offset date time string literal"],null),Hma],null)],null));
$CLJS.AL.o(null,$CLJS.tG,function(a){return $CLJS.m($CLJS.nV?$CLJS.nV(tX,a):nX.call(null,tX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Ml,null,$CLJS.SJ,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(sX,a):nX.call(null,sX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.OI,null,$CLJS.Ml,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(rX,a):nX.call(null,rX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.XK,null,$CLJS.Ml,null],null),null):$CLJS.Ml});$CLJS.jL($CLJS.uX,sX);$CLJS.jL(qX,rX);
$CLJS.jL($CLJS.yX,tX);$CLJS.jL(yma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.xk,$CLJS.uX,qX,$CLJS.yX],null));$CLJS.CX=$CLJS.Fi("^\\d{4}-\\d{2}$");$CLJS.jL($CLJS.wX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"year-month string literal"],null),$CLJS.CX],null));$CLJS.DX=$CLJS.Fi("^\\d{4}$");$CLJS.jL($CLJS.pX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"year string literal"],null),$CLJS.DX],null));
$CLJS.jL(vX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.wL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,$CLJS.xL],null)],null)],null));$CLJS.sV($CLJS.ll,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.dv,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,$CLJS.ll],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,vX],null),$CLJS.Lb],null));