var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var U$=function(a){return new $CLJS.yf(null,function(){return $CLJS.nf(a.A?a.A():a.call(null),U$(a))},null,null)},V$=function(a,b){return new $CLJS.yf(null,function(){var c=$CLJS.A(b);if(c){var d=$CLJS.C(c),e=a.g?a.g(d):a.call(null,d),f=$CLJS.nf(d,$CLJS.vi(function(g){return $CLJS.F.h(e,a.g?a.g(g):a.call(null,g))},$CLJS.D(c)));return $CLJS.nf(f,V$(a,new $CLJS.yf(null,function(){return $CLJS.kg($CLJS.E(f),c)},null,null)))}return null},null,null)},Dwa=function(a,b){switch(a){case "minutes":return $CLJS.ZZ("Minute",
"Minutes",b);case "hours":return $CLJS.ZZ("Hour","Hours",b);case "days":return $CLJS.ZZ("Day","Days",b);case "weeks":return $CLJS.ZZ("Week","Weeks",b);case "months":return $CLJS.ZZ("Month","Months",b);case "quarters":return $CLJS.ZZ("Quarter","Quarters",b);case "years":return $CLJS.ZZ("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}},Ewa=function(a,b,c){b=parseInt(b);c=Dwa(c,b);a=new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.F.h(b,1)],null);if($CLJS.F.h(new $CLJS.S(null,
2,5,$CLJS.T,["past",!0],null),a))return $CLJS.UH("Previous {0}",$CLJS.G([c]));if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,["past",!1],null),a))return $CLJS.UH("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,["next",!0],null),a))return $CLJS.UH("Next {0}",$CLJS.G([c]));if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,["next",!1],null),a))return $CLJS.UH("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));},Gwa=function(a){return $CLJS.Nu(a,
Fwa,function(b){return["\\",$CLJS.p.g(b)].join("")})},W$=function(a,b,c,d,e){this.tag=a;this.source=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264},Iwa=function(a){var b=$CLJS.kz(a,Hwa),c=$CLJS.rg.h($CLJS.C,$CLJS.RH(X$,a)),d=function(){var e=$CLJS.E(b),f=$CLJS.E(c);return e>f?e:f}();a=$CLJS.qg.h(b,$CLJS.jg(d-$CLJS.E(b),U$($CLJS.ag(""))));d=$CLJS.qg.h(c,$CLJS.jg(d-$CLJS.E(c),U$($CLJS.ag(""))));a=$CLJS.og.h(a,d);return $CLJS.rg.h(function(e){var f=$CLJS.Di(X$,e);return $CLJS.m(f)?($CLJS.J(f,
0,null),f=$CLJS.J(f,1,null),new W$(f,e,null,null,null)):e},a)},Jwa=function(a){return $CLJS.sg(function(b){return"string"===typeof $CLJS.C(b)?new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.bv.g(b)],null):b},$CLJS.G([V$(function(b){return"string"===typeof b||$CLJS.Gb($CLJS.ll.g(b))},a)]))},Kwa=function(a,b,c,d){return $CLJS.rg.h(function(e){if(e instanceof W$){var f=$CLJS.U.j;var g=$CLJS.pl.g(e),l=$CLJS.M.h(a,g);g=$CLJS.ll.g(l);l=$CLJS.Rk.g(l);if($CLJS.m(g))try{var n=Y$.j(l,g,b);var q=$CLJS.m(c)?Gwa(n):n}catch(u){if(u instanceof
Error)q=Y$.j($CLJS.Pj,g,b);else throw u;}else q=null;e=f.call($CLJS.U,e,$CLJS.ll,q)}return e},d)},Nwa=function(a){a=$CLJS.bv.g($CLJS.rg.h(function(b){return b instanceof W$?b:$CLJS.Nu(b,Lwa,"")},a));return $CLJS.Nu(a,Mwa,$CLJS.Zd)},Owa=function(a){return $CLJS.bG($CLJS.C(Z$(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.NO],null),new $CLJS.S(null,1,5,$CLJS.T,[a],null))),$CLJS.Oi)},Z$=function Z$(a,b){if($CLJS.Gb($CLJS.A(a)))a=(0,$CLJS.L7)(b);else{var d=$CLJS.M,e=d.h;var f=$CLJS.ti(a);b=$CLJS.Pf([$CLJS.$d(a),
b]);f=Z$.h?Z$.h(f,b):Z$.call(null,f,b);a=e.call(d,f,$CLJS.$d(a))}return a};var Y$=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Mk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.Oi.g(f)},$CLJS.Pj,e,a,b,c,d)}();Y$.o(null,$CLJS.UP,function(a,b,c){return(0,$CLJS.TL)(b).locale(c).format("MMMM D, YYYY")});
Y$.o(null,$CLJS.RO,function(a,b,c){a=(0,$CLJS.TL)(b,"YYYY-MM").locale(c);return $CLJS.m(a.isValid())?a.format("MMMM, YYYY"):""});Y$.o(null,$CLJS.tP,function(a,b,c){a=(0,$CLJS.TL)(b,"[Q]Q-YYYY").locale(c);return $CLJS.m(a.isValid())?a.format("[Q]Q, YYYY"):""});Y$.o(null,$CLJS.EO,function(a,b,c){b=$CLJS.kz(b,/~/);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.m($CLJS.m(a)?b:a)?[$CLJS.p.g(Y$.j($CLJS.UP,a,c))," - ",$CLJS.p.g(Y$.j($CLJS.UP,b,c))].join(""):""});
Y$.o(null,$CLJS.uP,function(a,b){function c(d,e){return $CLJS.D($CLJS.Ei(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.VH("Today");if(c(/^thisweek$/,b))return $CLJS.VH("This Week");if(c(/^thismonth$/,b))return $CLJS.VH("This Month");if(c(/^thisquarter$/,b))return $CLJS.VH("This Quarter");if(c(/^thisyear$/,b))return $CLJS.VH("This Year");if(c(/^past1days$/,b))return $CLJS.VH("Yesterday");if(c(/^next1days$/,b))return $CLJS.VH("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.R.h(Ewa,
a);throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));});Y$.o(null,$CLJS.kS,function(a,b,c){function d(e,f){return $CLJS.Zd($CLJS.Ei(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.m(a))return Y$.j($CLJS.uP,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.m(a))return Y$.j($CLJS.UP,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.m(a)?Y$.j($CLJS.EO,a,c):Y$.j($CLJS.uP,b,c)});
Y$.o(null,$CLJS.Pj,function(a,b){$CLJS.ne(b)?(a=$CLJS.E(b),b=$CLJS.m($CLJS.F.h?$CLJS.F.h(1,a):$CLJS.F.call(null,1,a))?$CLJS.p.g($CLJS.C(b)):$CLJS.m($CLJS.F.h?$CLJS.F.h(2,a):$CLJS.F.call(null,2,a))?$CLJS.UH("{0} and {1}",$CLJS.G([$CLJS.C(b),$CLJS.Zd(b)])):$CLJS.UH("{0}, {1}, and {2}",$CLJS.G([$CLJS.bv.h(", ",$CLJS.Lu(2,b)),$CLJS.Td(b,$CLJS.E(b)-2),$CLJS.$d(b)]))):b=$CLJS.p.g(b);return b});var Fwa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,X$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,Hwa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.h=W$.prototype;$CLJS.h.toString=function(){var a=$CLJS.ll.g(this);return $CLJS.m(a)?a:this.source};$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pl,this.tag],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rF,this.source],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pl,$CLJS.rF],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-884441273^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.tag,b.tag)&&$CLJS.F.h(this.source,b.source)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.rF,null,$CLJS.pl,null],null),null),b)?$CLJS.wm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new W$(this.tag,this.source,this.F,$CLJS.Rf($CLJS.wm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "tag":case "source":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.pl,b):$CLJS.O.call(null,$CLJS.pl,b))?new W$(c,this.source,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.rF,b):$CLJS.O.call(null,$CLJS.rF,b))?new W$(this.tag,c,this.F,this.m,null):new W$(this.tag,this.source,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.pl,this.tag),new $CLJS.Je($CLJS.rF,this.source)],null),this.m))};
$CLJS.h.O=function(a,b){return new W$(this.tag,this.source,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};var Lwa=/\[\[.+\]\]/,Mwa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.h(arguments[0],arguments[1]);case 4:return $$.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.Ra("metabase.shared.parameters.parameters.substitute_tags",$$);$$.h=function(a,b){return $$.D(a,b,"en",!0)};$$.D=function(a,b,c,d){return $CLJS.m(a)?(b=$CLJS.hF(b),b=$CLJS.Ku(b,Owa),Nwa(Jwa(Kwa(b,c,d,Iwa(a))))):null};$$.v=4;module.exports={tag_names:function(a){a=$CLJS.si($CLJS.rg.h($CLJS.Zd,$CLJS.RH(X$,$CLJS.m(a)?a:"")));return $CLJS.bn(a)},substitute_tags:$$};