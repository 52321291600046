var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.card.js");require("./metabase.lib.column_group.js");require("./metabase.lib.common.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.filter.js");require("./metabase.lib.join.js");require("./metabase.lib.limit.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metric.js");require("./metabase.lib.native.js");require("./metabase.lib.normalize.js");require("./metabase.lib.order_by.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.segment.js");require("./metabase.lib.stage.js");require("./metabase.lib.table.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.shared.util.namespaces.js");
'use strict';var Nta=function(a,b){$CLJS.Sb(function(c,d){return a.g?a.g(d):a.call(null,d)},null,b)},t8=function(a,b){return $CLJS.Vc($CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Uc(c,d):c},$CLJS.Tc($CLJS.Lg),b))},u8=function(a,b){if(b=$CLJS.A(b)){var c=$CLJS.A(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.A(c)?(c=u8($CLJS.M.h(a,b),c),$CLJS.je(c)?$CLJS.wm.h(a,b):$CLJS.U.j(a,b,c)):$CLJS.wm.h(a,b)}return a},Ota=function(a,b,c,d){return function q(f,g,l,n){var u=$CLJS.A(g);g=$CLJS.C(u);
u=$CLJS.D(u);var v=$CLJS.Ke(f,g);return $CLJS.m(v)?u?$CLJS.U.j(f,g,q($CLJS.uc(v),u,l,n)):$CLJS.U.j(f,g,$CLJS.R.j(l,$CLJS.uc(v),n)):f}(a,b,c,d)},Pta=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Ota(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.z(b.slice(3),0,null):null)},Qta=function(a,b){return $CLJS.C($CLJS.eg(function(c){if($CLJS.m($CLJS.m(c)?b:c)){var d=$CLJS.zF.g(c);c=$CLJS.AF.g(c);d=$CLJS.m($CLJS.Ei(d,b))?$CLJS.Nu(b,d,c):null}else d=
null;return d},a))},v8=function(a){a=$CLJS.uH(a);var b=$CLJS.OF(a);b||($CLJS.m(a)?(b=$CLJS.m(a)?!$CLJS.Ie($CLJS.r($CLJS.AH),$CLJS.uH(a).toLowerCase()):null,b=$CLJS.Gb(b)):b=null);return $CLJS.m(b)?a:Qta($CLJS.Pc($CLJS.r($CLJS.wH)),a)},Rta=function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.k(null,4,[$CLJS.ml,$CLJS.rL,$CLJS.PJ,b instanceof $CLJS.N?$CLJS.ui(b):b,$CLJS.Jk,c,$CLJS.fl,$CLJS.Om.h(a,2)],null):null},w8=function(a,b){if(null!=a&&null!=a.oh)a=a.oh(a,b);else{var c=
w8[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=w8._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.metrics",a);}return a},x8=function(a){return $CLJS.$_(a)?$CLJS.Ju(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.HG],null)):null},Sta=function(a,b,c,d){var e=$CLJS.F.h($CLJS.OQ,$CLJS.C(b))?$CLJS.a3(d,$CLJS.a0(c)):d;return Pta(a,b,function(f){return $CLJS.Mg(function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);
if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.F.h(x8(B),x8(c))?e:B;x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.F.h(x8(x),x8(c))?e:x,n($CLJS.zd(q)))}return null}},null,null)}(f)}())})},y8=function(a,b,c){var d=$CLJS.Ju(a,b);if($CLJS.m(d)){var e=x8(c),f=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(b),$CLJS.$d(b)],null);c=$CLJS.J(f,0,null);
f=$CLJS.J(f,1,null);d=$CLJS.oh.j($CLJS.Lg,$CLJS.Hm.g($CLJS.Em.h($CLJS.pi([e]),x8)),d);d=$CLJS.F.h(b,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wO],null))&&$CLJS.Wf(function(g){return $CLJS.$_(g)&&$CLJS.F.h($CLJS.C(g),$CLJS.zG)},d)?null:d;if($CLJS.A(d))return $CLJS.Pu(a,b,d);if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UO,$CLJS.kZ],null),new $CLJS.S(null,2,5,$CLJS.T,[c,f],null)))throw $CLJS.zj($CLJS.VH("Cannot remove the final join condition"),new $CLJS.k(null,1,[$CLJS.kZ,$CLJS.Ju(a,b)],null));return $CLJS.F.h(new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.UO,$CLJS.wO],null),new $CLJS.S(null,2,5,$CLJS.T,[c,f],null))?$CLJS.p_.D(a,$CLJS.ie(b),$CLJS.wm,f):u8(a,b)}return a},Tta=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.hZ);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null},z8=function(a,b,c,d){a=$CLJS.e0(a);var e=$CLJS.Qf(a);a=$CLJS.M.h(e,$CLJS.hZ);b=$CLJS.m(b)?b:-1;var f=$CLJS.V0(e,b);f=!($CLJS.A($CLJS.MP.g(f))||$CLJS.A($CLJS.FP.g(f)));c=$CLJS.W0(e,b,$CLJS.Jm,$CLJS.G([c,function(g){return $CLJS.kf.h($CLJS.Mg(g),$CLJS.jW.g(d))}]));
return f?$CLJS.Jm.R($CLJS.f6(c,b,function(g){return $CLJS.q_.j($CLJS.wm.l(g,$CLJS.YR,$CLJS.G([$CLJS.wO])),$CLJS.UO,function(l){return $CLJS.Im.h(function(n){return $CLJS.wm.h(n,$CLJS.wO)},l)})}),$CLJS.hZ,$CLJS.Em.h(function(g){return $CLJS.oh.h($CLJS.Lg,g)},$CLJS.Om),0,$CLJS.U0(a,b)+1):c},A8=function(a){var b=$CLJS.EH(a);b=$CLJS.m($CLJS.ik.g(b))?null:$CLJS.CG.g(b);return $CLJS.m(b)?$CLJS.dW(a,$CLJS.U,$CLJS.G([$CLJS.ik,b])):a},Uta=function(a){if($CLJS.F.h($CLJS.ml.g($CLJS.V0(a,-1)),$CLJS.IZ))return null;
try{return $CLJS.a1.h(a,a)}catch(c){if(c instanceof Error){a=c;var b=$CLJS.XF($CLJS.Wu);$CLJS.m($CLJS.dG("metabase.lib.metadata.calculation",b))&&(a instanceof Error?$CLJS.eG("metabase.lib.metadata.calculation",b,$CLJS.UB.l($CLJS.G([$CLJS.UH("Error calculating display name for query: {0}",$CLJS.G([$CLJS.SZ(a)]))])),a):$CLJS.eG("metabase.lib.metadata.calculation",b,$CLJS.UB.l($CLJS.G([a,$CLJS.UH("Error calculating display name for query: {0}",$CLJS.G([$CLJS.SZ(a)]))])),null));return null}throw c;}},
Vta=function(a,b){a=$CLJS.m2(a);b=$CLJS.m(b)?b:$CLJS.wz;return 0===a?$CLJS.VH("Now"):0>a?$CLJS.UH("{0} {1} ago",$CLJS.G([Math.abs(a),$CLJS.F1.h(Math.abs(a),b).toLowerCase()])):$CLJS.UH("{0} {1} from now",$CLJS.G([a,$CLJS.F1.h(a,b).toLowerCase()]))},Wta=function(a){var b=new $CLJS.S(null,9,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Is not")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.cm,$CLJS.TI,$CLJS.VH("Greater than")],
null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.am,$CLJS.TI,$CLJS.VH("Less than")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.JI,$CLJS.TI,$CLJS.VH("Between")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.lm,$CLJS.TI,$CLJS.VH("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.mm,$CLJS.TI,$CLJS.VH("Less than or equal to")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.xI,$CLJS.TI,$CLJS.VH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.nJ,$CLJS.TI,$CLJS.VH("Not empty")],null)],null);return $CLJS.d2(a,
$CLJS.Sj)?b:$CLJS.d2(a,$CLJS.Kj)?b:$CLJS.d2(a,$CLJS.$H)?new $CLJS.S(null,8,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Is not")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.KI,$CLJS.TI,$CLJS.VH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$I,$CLJS.TI,$CLJS.VH("Not empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.KJ,$CLJS.TI,$CLJS.VH("Contains")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.vJ,$CLJS.TI,
$CLJS.VH("Does not contain")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.uJ,$CLJS.TI,$CLJS.VH("Starts with")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.mK,$CLJS.TI,$CLJS.VH("Ends with")],null)],null):$CLJS.d2(a,$CLJS.Fl)?new $CLJS.S(null,7,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Excludes")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.am,$CLJS.TI,$CLJS.VH("Before")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.cm,
$CLJS.TI,$CLJS.VH("After")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.JI,$CLJS.TI,$CLJS.VH("Between")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.xI,$CLJS.TI,$CLJS.VH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.nJ,$CLJS.TI,$CLJS.VH("Not empty")],null)],null):$CLJS.d2(a,$CLJS.gl)?new $CLJS.S(null,8,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Is not")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.UI,$CLJS.TI,
$CLJS.VH("Inside")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.cm,$CLJS.TI,$CLJS.VH("Greater than")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.am,$CLJS.TI,$CLJS.VH("Less than")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.JI,$CLJS.TI,$CLJS.VH("Between")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.lm,$CLJS.TI,$CLJS.VH("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.mm,$CLJS.TI,$CLJS.VH("Less than or equal to")],null)],null):$CLJS.d2(a,$CLJS.yl)?new $CLJS.S(null,9,5,$CLJS.T,[new $CLJS.k(null,
2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Equal to")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Not equal to")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.cm,$CLJS.TI,$CLJS.VH("Greater than")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.am,$CLJS.TI,$CLJS.VH("Less than")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.JI,$CLJS.TI,$CLJS.VH("Between")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.lm,$CLJS.TI,$CLJS.VH("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.mm,$CLJS.TI,
$CLJS.VH("Less than or equal to")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.xI,$CLJS.TI,$CLJS.VH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.nJ,$CLJS.TI,$CLJS.VH("Not empty")],null)],null):$CLJS.d2(a,$CLJS.Ml)?new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Is not")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.KJ,$CLJS.TI,$CLJS.VH("Contains")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.vJ,
$CLJS.TI,$CLJS.VH("Does not contain")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.xI,$CLJS.TI,$CLJS.VH("Is null")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.nJ,$CLJS.TI,$CLJS.VH("Not null")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.KI,$CLJS.TI,$CLJS.VH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$I,$CLJS.TI,$CLJS.VH("Not empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.uJ,$CLJS.TI,$CLJS.VH("Starts with")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.mK,$CLJS.TI,$CLJS.VH("Ends with")],null)],null):
$CLJS.d2(a,$CLJS.Uk)?new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Is not")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.xI,$CLJS.TI,$CLJS.VH("Is null")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.nJ,$CLJS.TI,$CLJS.VH("Not null")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.KI,$CLJS.TI,$CLJS.VH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$I,$CLJS.TI,$CLJS.VH("Not empty")],null)],null):$CLJS.d2(a,
$CLJS.ul)?new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.xI,$CLJS.TI,$CLJS.VH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.nJ,$CLJS.TI,$CLJS.VH("Not empty")],null)],null):new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Is")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Is not")],null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.xI,$CLJS.TI,$CLJS.VH("Is null")],
null),new $CLJS.k(null,2,[$CLJS.AV,$CLJS.nJ,$CLJS.TI,$CLJS.VH("Not null")],null)],null)},Xta=function(a){return $CLJS.q2.g(a)},Yta=function(a,b){var c=$CLJS.oh.j($CLJS.P,$CLJS.yn(function(e){var f=$CLJS.gW(e);f=$CLJS.Q0.h?$CLJS.Q0.h(f,b):$CLJS.Q0.call(null,f,b);return $CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[f,e],null):null}),a);c=$CLJS.rg.h(c,b);var d=$CLJS.vW($CLJS.Gm.h($CLJS.h2,$CLJS.i2),c);if($CLJS.m(d))return d;c=$CLJS.C(c);if($CLJS.m(c))return c;c=$CLJS.vW($CLJS.h2,a);if($CLJS.m(c))return c;
c=$CLJS.vW($CLJS.i2,a);return $CLJS.m(c)?c:$CLJS.C(a)},Zta=function(a){return"string"===typeof a?(0,$CLJS.Ca)($CLJS.Nu(a,/ id$/i,"")):null},$ta=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Yi(function(){return v8(b)});var d=new $CLJS.Yi(function(){return v8(a)});return $CLJS.F.h(a,b)||$CLJS.F.h(a,$CLJS.r(c))||$CLJS.F.h($CLJS.r(d),b)||$CLJS.F.h($CLJS.r(d),$CLJS.r(c))}return c}return c},aua=function(a,b,c){var d=$CLJS.a1.h($CLJS.F.h($CLJS.ml.g(b),$CLJS.MZ)?b:a,
b),e=$CLJS.m(c)?Zta($CLJS.a1.h(a,c)):null,f=$ta(d,e);return function(){var g=function(){if($CLJS.m(d)){if($CLJS.m(e)){var l=$CLJS.Gb($CLJS.Di(/id/i,e));return l?(l=!f)?[$CLJS.p.g(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.m(g)?g:$CLJS.m(d)?d:$CLJS.m(e)?e:"source"}()},B8=function(a){return $CLJS.$_(a)&&$CLJS.F.h($CLJS.C(a),$CLJS.DQ)},bua=function(a,b,c,d){return function l(f,g){try{if(B8(g)&&$CLJS.ye($CLJS.Q0.j?$CLJS.Q0.j(a,g,d):$CLJS.Q0.call(null,a,g,d)))return $CLJS.X3(g,c);throw $CLJS.Z;
}catch(q){if(q instanceof Error){var n=q;if(n===$CLJS.Z)return $CLJS.d_(l,f,g);throw n;}throw q;}}($CLJS.Lg,b)},cua=function(a,b,c,d,e){b=bua(a,b,c,e);return function n(g,l){try{if($CLJS.qe(l)&&4===$CLJS.E(l))try{var q=$CLJS.Td(l,2);if(B8(q))try{var u=$CLJS.Td(l,3);if(B8(u)){var v=$CLJS.Td(l,3),x=$CLJS.Td(l,2),y=$CLJS.Td(l,0),B=$CLJS.Td(l,1),H=$CLJS.Ie($CLJS.EH(x),$CLJS.lQ),I=$CLJS.Ie($CLJS.EH(v),$CLJS.lQ);if(H||I){if(H&&I){var Q=$CLJS.dW(x,$CLJS.wm,$CLJS.G([$CLJS.lQ])),Y=$CLJS.dW(v,$CLJS.wm,$CLJS.G([$CLJS.lQ])),
aa=null==($CLJS.Q0.j?$CLJS.Q0.j(a,Q,d):$CLJS.Q0.call(null,a,Q,d));var ha=aa?$CLJS.Q0.j?$CLJS.Q0.j(a,Y,d):$CLJS.Q0.call(null,a,Y,d):aa;return $CLJS.m(ha)?new $CLJS.S(null,4,5,$CLJS.T,[y,B,x,Y],null):new $CLJS.S(null,4,5,$CLJS.T,[y,B,Q,v],null)}return l}return $CLJS.m($CLJS.Q0.j?$CLJS.Q0.j(a,v,d):$CLJS.Q0.call(null,a,v,d))?new $CLJS.S(null,4,5,$CLJS.T,[y,B,$CLJS.X3(x,c),v],null):new $CLJS.S(null,4,5,$CLJS.T,[y,B,x,$CLJS.X3(v,c)],null)}throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){var qa=Ea;if(qa===
$CLJS.Z)throw $CLJS.Z;throw qa;}throw Ea;}else throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){qa=Ea;if(qa===$CLJS.Z)throw $CLJS.Z;throw qa;}throw Ea;}else throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){qa=Ea;if(qa===$CLJS.Z)return $CLJS.d_(n,g,l);throw qa;}throw Ea;}}($CLJS.Lg,b)},dua=function(a,b){var c=$CLJS.c3();Nta(c,b);return c(a)},eua=function(a,b,c){var d=$CLJS.V0(a,b);b=$CLJS.N5(a,b,d);var e=$CLJS.A($CLJS.ug($CLJS.Hb,function v(q,u){try{if($CLJS.qe(u)&&1<=$CLJS.E(u))try{var x=$CLJS.Om.j(u,
0,1);if($CLJS.qe(x)&&1===$CLJS.E(x))try{var y=$CLJS.Td(x,0);if($CLJS.O(y,$CLJS.DQ))return new $CLJS.S(null,1,5,$CLJS.T,[u],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)return $CLJS.sZ(v,q,u);throw B;}throw H;}}($CLJS.Lg,$CLJS.kZ.g(c))));e=Yta(b,e);var f=dua(aua(a,
c,e),$CLJS.rg.h($CLJS.vP,$CLJS.UO.g(d))),g=$CLJS.Im.h($CLJS.gW,b),l=$CLJS.Im.h($CLJS.gW,$CLJS.n5($CLJS.U3(a,$CLJS.hZ.g(c))));return $CLJS.X3($CLJS.Jm.j(c,$CLJS.kZ,function(q){return $CLJS.Im.h(function(u){return cua(a,u,f,g,l)},q)}),f)},fua=function(a){return $CLJS.kZ.g(a)},gua=function(a){return $CLJS.wO.g(a)},hua=function(a){return $CLJS.M.j(a,$CLJS.WK,$CLJS.wQ)},iua=function(a,b){return $CLJS.U.j(a,$CLJS.WK,b)},C8=function(a){a=$CLJS.fj(function(d){return $CLJS.i2(d)?$CLJS.a4:$CLJS.h2(d)?$CLJS.$3:
$CLJS.oz},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.a4);var c=$CLJS.M.h(b,$CLJS.$3);b=$CLJS.M.h(b,$CLJS.oz);return $CLJS.qg.l(a,c,$CLJS.G([b]))},D8=function(){return new $CLJS.k(null,3,[$CLJS.ml,$CLJS.iY,$CLJS.AV,$CLJS.$l,$CLJS.TI,$CLJS.VH("Equal to")],null)},jua=function(a,b,c){var d=$CLJS.Ck.g(c);return $CLJS.m(d)?$CLJS.vW(function(e){e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.b4);return $CLJS.h2(e)&&$CLJS.F.h(f,d)},$CLJS.N5(a,b,$CLJS.V0(a,b))):null},kua=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.g0);
switch(b instanceof $CLJS.N?b.S:null){case "source/implicitly-joinable":return new $CLJS.k(null,2,[$CLJS.e4,$CLJS.Lqa,$CLJS.iR,$CLJS.iR.g(a)],null);case "source/joins":return new $CLJS.k(null,2,[$CLJS.e4,$CLJS.Mqa,$CLJS.lQ,$CLJS.O5(a)],null);default:return new $CLJS.k(null,1,[$CLJS.e4,$CLJS.Kqa],null)}},lua=function(a){return $CLJS.Im.h(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.U.l(c,$CLJS.ml,$CLJS.f4,$CLJS.G([$CLJS.g4,b]))},$CLJS.fj(kua,a))},mua=function(a){return $CLJS.g4.g(a)},
nua=function(a){return $CLJS.d0.g(a)},oua=function(a,b){if($CLJS.A(a)){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);var d=$CLJS.J(b,2,null),e=$CLJS.PQ.g($CLJS.EH(d));return $CLJS.Im.h(function(f){return $CLJS.F.h($CLJS.AV.g(f),c)?$CLJS.q_.j($CLJS.U.j(f,$CLJS.B0,!0),$CLJS.d0,function(g){var l=$CLJS.Im.h($CLJS.gW,g),n=function(){var q=$CLJS.dW(d,$CLJS.wm,$CLJS.G([$CLJS.PQ]));return $CLJS.Q0.h?$CLJS.Q0.h(q,l):$CLJS.Q0.call(null,q,l)}();return $CLJS.m(n)?$CLJS.Im.j(function(q,u){q=$CLJS.F.h(q,n)?$CLJS.U.j(u,
$CLJS.B0,!0):u;return null!=e?$CLJS.Z7(q,e):q},l,g):g}):f},a)}return null},pua=function(a,b,c){var d=$CLJS.V0(a,b);a=$CLJS.N5(a,b,d);var e=$CLJS.jG(c);return $CLJS.Xf(function(f){return $CLJS.F.h($CLJS.jG($CLJS.V.g(f)),e)},a)},qua=function(a,b){var c=$CLJS.Jm.D(a,$CLJS.OQ,$CLJS.cg($CLJS.kf,$CLJS.Lg),b);return $CLJS.qe($CLJS.wO.g(a))?$CLJS.Jm.D(c,$CLJS.wO,$CLJS.kf,$CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zG,$CLJS.P,$CLJS.a0(b)],null))):c},rua=function(a,b){return function e(d){return new $CLJS.yf(null,
function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q);$CLJS.F.h($CLJS.g0.g(u),$CLJS.x0)&&(u=$CLJS.gW(u),n.add(u));q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}n=$CLJS.C(f);if($CLJS.F.h($CLJS.g0.g(n),$CLJS.x0))return $CLJS.nf($CLJS.gW(n),e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}($CLJS.$0(a,b,$CLJS.V0(a,b)))},sua=function(a){var b=$CLJS.YP.g($CLJS.C($CLJS.hZ.g(a)));
return $CLJS.m(b)?$CLJS.Rf(w8($CLJS.r0(a),b)):null},tua=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.CG);return $CLJS.Xf(function(c){return $CLJS.OH(b,c)},$CLJS.SL)},uua=function(a,b){var c=x8(b);return function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{if($CLJS.F.h($CLJS.HG.g($CLJS.Td(f,1)),c)){var l=$CLJS.Td(f,0);return $CLJS.U.j(f,0,$CLJS.V7.g?$CLJS.V7.g(l):$CLJS.V7.call(null,l))}throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;
}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)return $CLJS.d_(g,e,f);throw l;}throw n;}}($CLJS.Lg,a)},F8=function(a,b){var c=$CLJS.Ai(0,$CLJS.E(E8.h(a,b)));return $CLJS.qg.l(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.YR],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.FP],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.NZ],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wO],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.MP],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.OQ],null)],null),function(){return function f(e){return new $CLJS.yf(null,
function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);q.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UO,v,$CLJS.wO],null));u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UO,q,$CLJS.wO],null),f($CLJS.zd(g)))}return null}},null,null)}(c)}(),$CLJS.G([function(){return function f(e){return new $CLJS.yf(null,
function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);q.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UO,v,$CLJS.kZ],null));u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UO,q,$CLJS.kZ],null),f($CLJS.zd(g)))}return null}},null,null)}(c)}()]))},G8=function(a,b,c){var d=$CLJS.J(c,0,null),e=$CLJS.J(c,1,
null);e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.PQ),g=$CLJS.M.h(e,$CLJS.OR),l=$CLJS.J(c,2,null);return $CLJS.C($CLJS.vW(function(n){$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);$CLJS.J(n,0,null);$CLJS.J(n,1,null);n=$CLJS.J(n,2,null);return $CLJS.F.h($CLJS.C(n),d)&&$CLJS.F.h($CLJS.PQ.g($CLJS.Zd(n)),f)&&$CLJS.F.h($CLJS.OR.g($CLJS.Zd(n)),g)&&$CLJS.F.h($CLJS.$d(n),l)},$CLJS.dg($CLJS.Nm,$CLJS.YR.g($CLJS.V0(a,b)))))},vua=function(a,b,c,d){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.W0(a,b,$CLJS.p_,$CLJS.G([new $CLJS.S(null,
4,5,$CLJS.T,[$CLJS.YR,c,2,1],null),$CLJS.Em.h(function(e){return $CLJS.$F($CLJS.$f($CLJS.Cb),e)},$CLJS.Zm),d])):a},wua=function(a,b,c){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.W0(a,b,y8,$CLJS.G([new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.YR],null),$CLJS.Ju($CLJS.V0(a,b),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.YR,c],null))])):a},J8=function(a,b,c,d,e,f){var g=$CLJS.W0(a,b,f,$CLJS.G([d,e])),l=x8(e);return $CLJS.ki.h(a,g)?$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(n,q){try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var u=$CLJS.Td(q,
0);if($CLJS.O(u,$CLJS.OQ))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var I=$CLJS.a0(e);I=H8.R?H8.R(g,b,c,$CLJS.zG,I):H8.call(null,g,b,c,$CLJS.zG,I);return I8.D?I8.D(I,b,c,l):I8.call(null,I,b,c,l)}()],null);throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{u=$CLJS.Td(q,0);if($CLJS.O(u,$CLJS.MP))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var Q=H8.R?H8.R(g,b,c,$CLJS.MP,l):H8.call(null,g,b,c,$CLJS.MP,l);return I8.D?I8.D(Q,b,c,l):I8.call(null,Q,b,c,l)}()],null);throw $CLJS.Z;
}catch(Q){if(Q instanceof Error){var v=Q;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw Q;}else throw n;else throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var x=$CLJS.Td(q,0);if($CLJS.O(x,$CLJS.FP))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(v=Q,v===$CLJS.Z)try{x=$CLJS.Td(q,0);if($CLJS.O(x,$CLJS.wO))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?
I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){var y=Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw v;else throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(v=Q,v===$CLJS.Z)try{if($CLJS.qe(q)&&3===$CLJS.E(q))try{var B=$CLJS.Td(q,0);if($CLJS.O(B,$CLJS.UO))try{var H=$CLJS.Td(q,2);if($CLJS.O(H,$CLJS.wO))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=
Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=Y;if(y===$CLJS.Z)return new $CLJS.S(null,1,5,$CLJS.T,[g],null);throw y;}throw Y;}else throw v;else throw Q;}else throw n;else throw I;}}($CLJS.Lg,d)))):g},H8=function(a,b,c,d,e){var f=$CLJS.V0(a,b),g=$CLJS.sg(function(l){var n=$CLJS.Ju(f,l);return $CLJS.m(n)?$CLJS.xe(n)?$CLJS.eg(function(q){return $CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,
function y(v,x){try{if($CLJS.qe(x)&&3===$CLJS.E(x))try{var B=$CLJS.Td(x,0);if($CLJS.F.h(B,d))try{var H=$CLJS.Td(x,2);if($CLJS.F.h(H,e))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[l,q],null)],null);throw $CLJS.Z;}catch(Q){if(Q instanceof Error){var I=Q;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error){I=Q;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error){I=Q;if(I===$CLJS.Z)return $CLJS.sZ(y,
v,x);throw I;}throw Q;}}($CLJS.Lg,q))))},n):null:null},$CLJS.G([F8(a,b)]));return $CLJS.Sb(function(l,n){var q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);return J8(l,b,c,q,n,y8)},a,g)},I8=function(a,b,c,d){b=Tta(c,b);if($CLJS.m(b)){var e=$CLJS.V0(c,b);c=$CLJS.Xf(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.g0),l=$CLJS.M.h(f,$CLJS.E1);return $CLJS.F.h($CLJS.u0,g)&&$CLJS.F.h(d,l)?$CLJS.A0.g(f):null},$CLJS.N5(c,b,e));return $CLJS.m(c)?H8(a,b,a,$CLJS.DQ,c):a}return a},K8=function(a,b,c,d,e){var f=$CLJS.jW.g(c),
g=$CLJS.V0(a,b);c=$CLJS.vW(function(q){var u=$CLJS.Ju(g,q);if($CLJS.m(u)){var v=x8(f);return $CLJS.m($CLJS.Xf($CLJS.Em.j($CLJS.pi([v]),$CLJS.HG,$CLJS.Zd),u))?q:null}return null},F8(a,b));var l=(d=$CLJS.F.h($CLJS.VK,d))?$CLJS.jW.g(e):null;e=d?function(q,u,v){return Sta(q,u,v,l)}:y8;var n=$CLJS.F.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.FP],null),c);a=d&&n&&$CLJS.F.h($CLJS.C(f),$CLJS.C(l))&&$CLJS.F.h($CLJS.$d(f),$CLJS.$d(l))?vua(a,b,f,$CLJS.li($CLJS.Zd(l),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OR,$CLJS.PQ],
null))):n?wua(a,b,f):a;return $CLJS.m(c)?J8(a,b,a,c,f,e):a},xua=function(a){return $CLJS.Jm.D(a,$CLJS.hZ,$CLJS.kf,new $CLJS.k(null,1,[$CLJS.ml,$CLJS.CZ],null))},yua=function(a){if($CLJS.F.h(1,$CLJS.E($CLJS.hZ.g(a))))throw $CLJS.zj($CLJS.VH("Cannot drop the only stage"),new $CLJS.k(null,1,[$CLJS.hZ,$CLJS.hZ.g(a)],null));return $CLJS.Jm.j(a,$CLJS.hZ,$CLJS.Em.h($CLJS.Mg,$CLJS.ti))},L8=function L8(a){switch(arguments.length){case 2:return L8.h(arguments[0],arguments[1]);case 3:return L8.j(arguments[0],
arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};L8.h=function(a,b){return L8.j(a,null,b)};L8.j=function(a,b,c){b=$CLJS.m(b)?b:-1;c=$CLJS.jW.g(c);return $CLJS.W0(a,b,$CLJS.Jm,$CLJS.G([$CLJS.NZ,$CLJS.cg($CLJS.kf,$CLJS.Lg),c]))};L8.v=3;var M8=function M8(a){switch(arguments.length){case 1:return M8.g(arguments[0]);case 2:return M8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
M8.g=function(a){return M8.h(a,null)};M8.h=function(a,b){return $CLJS.Rf($CLJS.NZ.g($CLJS.V0(a,$CLJS.m(b)?b:-1)))};M8.v=2;var N8=function N8(a){switch(arguments.length){case 1:return N8.g(arguments[0]);case 2:return N8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};N8.g=function(a){return N8.h(a,-1)};
N8.h=function(a,b){var c=$CLJS.V0(a,b);a=$CLJS.N5(a,b,c);return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.yn(function(d){var e=$CLJS.Rf($CLJS.Im.h(function(f){return $CLJS.U.j(f,$CLJS.ml,$CLJS.iY)},Wta(d)));return $CLJS.m(e)?$CLJS.U.j(d,$CLJS.q2,e):null}),a))};N8.v=2;var O8=function O8(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return O8.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};
O8.l=function(a,b,c){return $CLJS.fW($CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AV.g(a),$CLJS.P,$CLJS.jW.g(b)],null),$CLJS.rg.h($CLJS.jW,c)))};O8.v=2;O8.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};var P8=function P8(a){switch(arguments.length){case 2:return P8.h(arguments[0],arguments[1]);case 3:return P8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
P8.h=function(a,b){return P8.j(a,-1,b)};P8.j=function(a,b,c){var d=$CLJS.Ie(c,$CLJS.vP)?c:eua(a,b,c);return $CLJS.W0(a,b,$CLJS.Jm,$CLJS.G([$CLJS.UO,function(e){return $CLJS.kf.h($CLJS.Mg(e),d)}]))};P8.v=3;var E8=function E8(a){switch(arguments.length){case 1:return E8.g(arguments[0]);case 2:return E8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};E8.g=function(a){return E8.h(a,-1)};
E8.h=function(a,b){return $CLJS.Rf($CLJS.M.h($CLJS.V0(a,b),$CLJS.UO))};E8.v=2;var Q8=function Q8(a){switch(arguments.length){case 1:return Q8.g(arguments[0]);case 2:return Q8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Q8.g=function(a){return Q8.h(a,-1)};Q8.h=function(a){a=$CLJS.T3($CLJS.r0(a));a=$CLJS.wF.g(a);return t8($CLJS.bg($CLJS.Ie,a),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.wQ,$CLJS.lO,$CLJS.mR,$CLJS.HR],null))};Q8.v=2;
var R8=function R8(a){switch(arguments.length){case 2:return R8.h(arguments[0],arguments[1]);case 3:return R8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};R8.h=function(a,b){return R8.j(a,-1,b)};R8.j=function(a,b){return C8($CLJS.M5(a,b,$CLJS.V0(a,b),new $CLJS.k(null,1,[$CLJS.n1,!1],null)))};R8.v=3;
var S8=function S8(a){switch(arguments.length){case 3:return S8.j(arguments[0],arguments[1],arguments[2]);case 4:return S8.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};S8.j=function(a,b,c){return S8.D(a,-1,b,c)};S8.D=function(a,b,c){return C8($CLJS.M5(a,b,c,new $CLJS.k(null,1,[$CLJS.n1,!1],null)))};S8.v=4;
var T8=function T8(a){switch(arguments.length){case 3:return T8.j(arguments[0],arguments[1],arguments[2]);case 4:return T8.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};T8.j=function(a,b,c){return T8.D(a,-1,b,c)};
T8.D=function(){return new $CLJS.S(null,6,5,$CLJS.T,[D8(),new $CLJS.k(null,3,[$CLJS.ml,$CLJS.iY,$CLJS.AV,$CLJS.cm,$CLJS.TI,$CLJS.VH("Greater than")],null),new $CLJS.k(null,3,[$CLJS.ml,$CLJS.iY,$CLJS.AV,$CLJS.am,$CLJS.TI,$CLJS.VH("Less than")],null),new $CLJS.k(null,3,[$CLJS.ml,$CLJS.iY,$CLJS.AV,$CLJS.lm,$CLJS.TI,$CLJS.VH("Greater than or equal to")],null),new $CLJS.k(null,3,[$CLJS.ml,$CLJS.iY,$CLJS.AV,$CLJS.mm,$CLJS.TI,$CLJS.VH("Less than or equal to")],null),new $CLJS.k(null,3,[$CLJS.ml,$CLJS.iY,
$CLJS.AV,$CLJS.CK,$CLJS.TI,$CLJS.VH("Not equal to")],null)],null)};T8.v=4;var U8=function U8(a){switch(arguments.length){case 2:return U8.h(arguments[0],arguments[1]);case 3:return U8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};U8.h=function(a,b){return U8.j(a,-1,b)};U8.j=function(a,b,c){c=$CLJS.vW($CLJS.i2,$CLJS.N5(a,b,c));return $CLJS.m(c)?(a=jua(a,b,c),$CLJS.m(a)?O8.l(D8(),a,$CLJS.G([c])):null):null};U8.v=3;
var V8=function V8(a){switch(arguments.length){case 2:return V8.h(arguments[0],arguments[1]);case 3:return V8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};V8.h=function(a,b){return V8.j(a,-1,b)};V8.j=function(a,b,c){for(;;)if($CLJS.F.h($CLJS.CH(c),$CLJS.iW))c=$CLJS.gW(c);else return z8(a,b,$CLJS.MP,c)};V8.v=3;
var W8=function W8(a){switch(arguments.length){case 1:return W8.g(arguments[0]);case 2:return W8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};W8.g=function(a){return W8.h(a,-1)};W8.h=function(a,b){return $CLJS.Rf($CLJS.MP.g($CLJS.V0(a,b)))};W8.v=2;
var X8=function X8(a){switch(arguments.length){case 1:return X8.g(arguments[0]);case 2:return X8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};X8.g=function(a){return X8.h(a,-1)};
X8.h=function(a,b){var c=function(){var f=$CLJS.wF.g($CLJS.T3($CLJS.r0(a)));return $CLJS.m(f)?f:$CLJS.oi}(),d=$CLJS.V0(a,b),e=$CLJS.N5(a,b,d);return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.Em.j($CLJS.tg(function(f){f=$CLJS.sW.g(f);var g=null==f;return g?g:c.g?c.g(f):c.call(null,f)}),$CLJS.yn(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.oW),l=$CLJS.M.h(f,$CLJS.lW);if($CLJS.Gb(g))return f;if($CLJS.F.h(l,$CLJS.Vt))return $CLJS.U.j(f,$CLJS.d0,e);g=$CLJS.Rf(t8(function(n){return $CLJS.e2(l,n)},e));return $CLJS.m(g)?
$CLJS.U.j(f,$CLJS.d0,g):null}),$CLJS.rg.g(function(f){return $CLJS.U.j(f,$CLJS.ml,$CLJS.nW)})),$CLJS.uW))};X8.v=2;var Y8=function Y8(a){switch(arguments.length){case 1:return Y8.g(arguments[0]);case 2:return Y8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
Y8.g=function(a){if($CLJS.Gb($CLJS.oW.g(a)))return $CLJS.fW(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AV.g(a),$CLJS.P],null));var b=$CLJS.AV.g(a);b=$CLJS.xa.h?$CLJS.xa.h("aggregation operator %s requires an argument",b):$CLJS.xa.call(null,"aggregation operator %s requires an argument",b);throw $CLJS.zj(b,new $CLJS.k(null,1,[$CLJS.Nqa,a],null));};Y8.h=function(a,b){return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AV.g(a),$CLJS.P,$CLJS.jW.g(b)],null))};Y8.v=2;
var Z8=function Z8(a){switch(arguments.length){case 2:return Z8.h(arguments[0],arguments[1]);case 3:return Z8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Z8.h=function(a,b){return Z8.j(a,-1,b)};Z8.j=function(a,b,c){c=$CLJS.gW($CLJS.de(c)?c.h?c.h(a,b):c.call(null,a,b):c);return z8(a,b,$CLJS.FP,c)};Z8.v=3;
var $8=function $8(a){switch(arguments.length){case 1:return $8.g(arguments[0]);case 2:return $8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$8.g=function(a){return $8.h(a,-1)};$8.h=function(a,b){return $CLJS.Rf($CLJS.FP.g($CLJS.V0(a,b)))};$8.v=2;
var a9=function a9(a){switch(arguments.length){case 1:return a9.g(arguments[0]);case 2:return a9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};a9.g=function(a){return a9.h(a,-1)};
a9.h=function(a,b){var c=function(){var f=$CLJS.V0(a,b);return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.U.j(B,$CLJS.k5,$CLJS.gW(B));x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.U.j(x,$CLJS.k5,$CLJS.gW(x)),n($CLJS.zd(q)))}return null}},null,null)}($CLJS.N5(a,
b,f))}(),d=$CLJS.oh.j($CLJS.P,$CLJS.Ho(function(f,g){var l=$CLJS.rg.h($CLJS.k5,c);g=$CLJS.Q0.j?$CLJS.Q0.j(a,g,l):$CLJS.Q0.call(null,a,g,l);return $CLJS.m(g)?new $CLJS.S(null,2,5,$CLJS.T,[g,f],null):null}),$8.h(a,b)),e=$CLJS.Rf(c);return null==e?null:$CLJS.oh.j($CLJS.Lg,$CLJS.rg.g(function(f){var g=$CLJS.k5.g(f);g=d.g?d.g(g):d.call(null,g);f=$CLJS.m(g)?$CLJS.U.j(f,$CLJS.j1,g):f;return $CLJS.wm.h(f,$CLJS.k5)}),e)};a9.v=2;
var b9=function b9(a){switch(arguments.length){case 3:return b9.j(arguments[0],arguments[1],arguments[2]);case 4:return b9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};b9.j=function(a,b,c){return b9.D(a,-1,b,c)};
b9.D=function(a,b,c,d){b=$CLJS.m(b)?b:-1;if($CLJS.m(pua(a,b,c)))throw $CLJS.zj("Expression name conflicts with a column in the same query stage",new $CLJS.k(null,1,[$CLJS.jS,c],null));return $CLJS.W0(a,b,qua,$CLJS.G([$CLJS.a3($CLJS.jW.g(d),c)]))};b9.v=4;var c9=function c9(a){switch(arguments.length){case 1:return c9.g(arguments[0]);case 2:return c9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};c9.g=function(a){return c9.h(a,-1)};
c9.h=function(a,b){return $CLJS.Rf($CLJS.OQ.g($CLJS.V0(a,b)))};c9.v=2;var d9=function d9(a){switch(arguments.length){case 2:return d9.h(arguments[0],arguments[1]);case 3:return d9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};d9.h=function(a,b){return d9.j(a,-1,b)};
d9.j=function(a,b,c){var d=$CLJS.oh.h($CLJS.P,$CLJS.dg(function(f,g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.a0(g),f],null)},c9.h(a,b))),e=$CLJS.V0(a,b);a=$CLJS.N5(a,b,e);return $CLJS.Rf(t8(function(f){return $CLJS.Gb(c)||$CLJS.ki.h($CLJS.g0.g(f),$CLJS.x0)||$CLJS.M.h(d,$CLJS.V.g(f))<c},a))};d9.v=3;
var e9=function e9(a){switch(arguments.length){case 1:return e9.g(arguments[0]);case 2:return e9.h(arguments[0],arguments[1]);case 3:return e9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};e9.g=function(a){return function(b,c){return e9.j(b,c,a)}};e9.h=function(a,b){return e9.j(a,-1,b)};
e9.j=function(a,b,c){var d=$CLJS.Rf($CLJS.Im.h($CLJS.gW,c));c=function(){return null==d?null:$CLJS.oh.j(d,$CLJS.Hm.g(function(e){return $CLJS.Q0.h?$CLJS.Q0.h(e,d):$CLJS.Q0.call(null,e,d)}),rua(a,b))}();return $CLJS.W0(a,b,$CLJS.cW,$CLJS.G([$CLJS.wO,c]))};e9.v=3;var f9=function f9(a){switch(arguments.length){case 1:return f9.g(arguments[0]);case 2:return f9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};f9.g=function(a){return f9.h(a,-1)};
f9.h=function(a,b){return $CLJS.wO.g($CLJS.V0(a,b))};f9.v=2;var g9=function g9(a){switch(arguments.length){case 1:return g9.g(arguments[0]);case 2:return g9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};g9.g=function(a){return g9.h(a,-1)};
g9.h=function(a,b){var c=f9.h(a,b),d=$CLJS.je(c)?$CLJS.ag(!0):function(e){var f=$CLJS.gW(e);return $CLJS.ye($CLJS.Xf(function(g){var l=$CLJS.O0.h(f,g);return $CLJS.m(l)?l:$CLJS.O0.h(A8(f),A8(g))},c))};return $CLJS.Im.h(function(e){return $CLJS.U.j(e,$CLJS.B0,d(e))},$CLJS.M5(a,b,$CLJS.V0(a,b),new $CLJS.k(null,3,[$CLJS.q1,!1,$CLJS.v1,!1,$CLJS.n1,!1],null)))};g9.v=2;
var h9=function h9(a){switch(arguments.length){case 1:return h9.g(arguments[0]);case 2:return h9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};h9.g=function(a){return h9.h(a,$CLJS.FJ)};h9.h=function(a,b){a=$CLJS.U7.g(a);b=$CLJS.m(b)?b:$CLJS.FJ;return $CLJS.U.j($CLJS.Mg(a),0,b)};h9.v=2;
var i9=function i9(a){switch(arguments.length){case 2:return i9.h(arguments[0],arguments[1]);case 3:return i9.j(arguments[0],arguments[1],arguments[2]);case 4:return i9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};i9.h=function(a,b){return i9.D(a,-1,b,null)};i9.j=function(a,b,c){return i9.D(a,-1,b,c)};
i9.D=function(a,b,c,d){b=$CLJS.m(b)?b:-1;var e=function(){var f=$CLJS.U7.g(c);return $CLJS.m(d)?$CLJS.U.j($CLJS.Mg(f),0,d):f}();return $CLJS.W0(a,b,$CLJS.Jm,$CLJS.G([$CLJS.YR,function(f){return $CLJS.kf.h($CLJS.Mg(f),e)}]))};i9.v=4;var j9=function j9(a){switch(arguments.length){case 1:return j9.g(arguments[0]);case 2:return j9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};j9.g=function(a){return j9.h(a,-1)};
j9.h=function(a,b){return $CLJS.Rf($CLJS.M.h($CLJS.V0(a,b),$CLJS.YR))};j9.v=2;var k9=function k9(a){switch(arguments.length){case 1:return k9.g(arguments[0]);case 2:return k9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};k9.g=function(a){return k9.h(a,-1)};
k9.h=function(a,b){function c(g){return $CLJS.Xf(function(l){var n=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var q=$CLJS.gW(g),u=$CLJS.O0.h(q,l);l=$CLJS.m(u)?u:$CLJS.O0.h(q,A8(l));return $CLJS.m(l)?n:null},d)}var d=$CLJS.dg(function(g,l){$CLJS.J(l,0,null);$CLJS.J(l,1,null);l=$CLJS.J(l,2,null);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)},j9.h(a,b)),e=$CLJS.Rf($CLJS.c8(a,b)),f=$CLJS.Rf($CLJS.g8.h(a,b));e=$CLJS.m($CLJS.m(e)?e:f)?$CLJS.qg.h(e,f):function(){var g=$CLJS.V0(a,b);return $CLJS.N5(a,b,g)}();
e=$CLJS.Rf(e);return null==e?null:$CLJS.oh.j($CLJS.Lg,$CLJS.Em.h($CLJS.tg(tua),$CLJS.rg.g(function(g){var l=c(g);return $CLJS.m(l)?$CLJS.U.j(g,$CLJS.k1,l):g})),e)};k9.v=2;var l9=function l9(a){switch(arguments.length){case 2:return l9.h(arguments[0],arguments[1]);case 3:return l9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};l9.h=function(a,b){return l9.j(a,-1,b)};l9.j=function(a,b,c){return K8(a,b,c,$CLJS.pta,null)};
l9.v=3;var m9=function m9(a){switch(arguments.length){case 3:return m9.j(arguments[0],arguments[1],arguments[2]);case 4:return m9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};m9.j=function(a,b,c){return m9.D(a,-1,b,c)};m9.D=function(a,b,c,d){return K8(a,b,c,$CLJS.VK,d)};m9.v=4;$CLJS.zua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(V8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.n9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Y8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Aua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(nua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Bua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(W8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Cua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(X8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Dua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(oua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.o9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.b6,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Eua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.P5,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Fua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.b8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Gua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Z8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Hua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(a9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Iua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Jua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(mua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Kua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(lua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Lua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Rta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Mua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(b9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Nua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(c9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Oua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(d9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Pua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(f9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Qua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(e9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Rua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(g9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Sua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(L8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Tua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(M8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Uua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(N8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Vua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Xta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Wua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(O8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Xua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Q8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Yua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(P8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Zua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.s8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.$ua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(R8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.ava=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(T8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.bva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(S8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.cva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(fua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.dva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(gua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.eva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(hua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.fva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(E8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.gva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(U8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.hva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.r8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.iva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(iua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.jva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.q8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.kva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.d1,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.lva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Uta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.mva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(sua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.nva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.ita,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.ova=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.jta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.pva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.Q7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.qva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.R7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.rva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(uua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.sva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(i9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.tva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(h9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.uva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(j9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.vva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(k9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.p9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.S3,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.wva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.o5,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.xva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.gW,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.yva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(l9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.zva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(m9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Ava=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(xua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Bva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(yua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Cva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.F1,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Dva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.n2,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Eva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Vta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.q9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.a6,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Fva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.$7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Gva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.Z7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();