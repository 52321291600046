var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var pL,xga,tL,yL;
$CLJS.nL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return $CLJS.Hu([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join(""))};$CLJS.oL=function(a){if("string"===typeof a){var b=$CLJS.Di(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.co(a));};pL=function(a){return $CLJS.OH(a,$CLJS.Yk)||$CLJS.OH(a,$CLJS.Qj)};
$CLJS.qL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-zero","metabase.lib.schema.common/int-greater-than-zero",1130338092);xga=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.rL=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.sL=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
tL=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.uL=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.vL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.wL=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.xL=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);yL=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.jL($CLJS.sL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Il,new $CLJS.k(null,1,[$CLJS.mk,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,"non-blank string"],null),$CLJS.$f($CLJS.OF)],null)],null));$CLJS.jL($CLJS.vL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rt,new $CLJS.k(null,1,[$CLJS.mk,0],null)],null));$CLJS.jL($CLJS.qL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rt,new $CLJS.k(null,1,[$CLJS.mk,1],null)],null));
$CLJS.jL(yL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Il,new $CLJS.k(null,2,[$CLJS.mk,36,$CLJS.Sl,36],null)],null));$CLJS.jL(tL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.dv,"valid semantic type",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ll);return["Not a valid semantic type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),pL],null));
$CLJS.jL($CLJS.xL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.dv,"valid base type",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ll);return["Not a valid base type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.OH(a,$CLJS.il)&&!pL(a)}],null));
$CLJS.jL($CLJS.wL,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.Rl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HG,yL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.xL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.xL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.El,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,
tL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uL,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.sL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.sL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TI,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.sL],null)],null)],null));
$CLJS.jL(xga,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Rl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ml,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,$CLJS.rL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PJ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xk,$CLJS.Il,$CLJS.Ak],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.wL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hl,$CLJS.Vt],null)],null)],null));